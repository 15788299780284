import React from 'react'
import style from './footer.module.css'

const Footer = () =>
  <footer className={style.footer}>
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 416.55 93.78' role='img'>
      <title>
        Lynchburg
      </title>
      <g id='b972daff-7b77-47a5-a127-3f656fda4135' data-name='Layer 2'>
        <g id='ac55332c-9a6d-45f8-8cea-1c0595cf5bbd' data-name='Layer 1'>
          <path fill='#F9F6EF' fillRule='evenodd' d='M228.24,0h4.09V12l5.77,7.12,8,9.82H214.24l7.95-9.82,6.05-7.47V0Zm3.24,70.62h4.79v6h-4.79v-6Zm-43.87-9.08h2.89v3.62h-2.89V61.54Zm13.93,6.34h2.89V71.5h-2.89V67.88Zm0-12.85h2.89v3.62h-2.89V55Zm0-6.34h2.89v3.62h-2.89V48.69ZM196.9,61.54h2.89v3.62H196.9V61.54Zm0-12.85h2.89v3.62H196.9V48.69Zm-4.76,12.85H195v3.62h-2.9V61.54Zm0,6.34H195V71.5h-2.9V67.88Zm0-12.85H195v3.62h-2.9V55Zm-4.53-6.34h2.89v3.62h-2.89V48.69Zm68.83,13.92H260v4.5h-3.59v-4.5Zm0,7.88H260V75h-3.59V70.49Zm0-23.84H260v4.5h-3.59v-4.5Zm5.63,7.88h3.59V59h-3.59V54.53Zm0-7.88h3.59v4.5h-3.59v-4.5Zm-45.15,24h4.78v6h-4.78v-6Zm0-21.25h4.78v6h-4.78v-6Zm21.77,0h4.79v6h-4.79v-6Zm-7.21,0h4.79v6h-4.79v-6Zm-7.17,0h4.78v6h-4.78v-6Zm-7.39-10.49h4.78v6h-4.78v-6Zm21.77,0h4.79v6h-4.79v-6Zm-7.21,0h4.79v6h-4.79v-6ZM216.92,60.13h4.78v6h-4.78v-6Zm21.77,0h4.79v6h-4.79v-6Zm30.17-16.81H253.77V86.18H247.6v-54H213V86.18h-6.36V41.78L184.52,29.11V93.78h84.34Z' />
          <path fill='#F9F6EF' fillRule='evenodd' d='M376,55.4h6.15v6.15H376V55.4Zm40.58,6.9L406.66,58V93.78h9.89V62.3ZM370.24,93.78H380V83.86h6.2v9.92h2.25V83.86h6.08v9.92h9.51V52.38H406V48.6h-37.8v3.78h2.06v41.4m-1.92,0h-9.89V74.18h0l9.89-4.33Zm23.93-19.86h6.15v6.15h-6.15V73.92Zm-8.1,0h6.14v6.15h-6.14V73.92Zm-8.18,0h6.15v6.15H376V73.92Zm16.28-9.09h6.15V71h-6.15V64.83Zm-8.1,0h6.14V71h-6.14V64.83Zm-8.18,0h6.15V71H376V64.83Zm16.28-9.43h6.15v6.15h-6.15V55.4Zm-8.1,0h6.14v6.15h-6.14Z' />
          <path fill='#F9F6EF' fillRule='evenodd' d='M178.24,41.69v45h3.35v7.13H109.84V86.65h4.25v-22h-2.33V59.37h29.47V41.69h-1.78V37.3H180.3v4.39ZM119.36,69.9v6.58h6.17V69.9Zm27.57-12.2v6.58h6.17V57.7Zm20.22,0v6.58h6.17V57.7Zm-10,0v6.58h6.17V57.7ZM146.93,45.63v6.58h6.17V45.63Zm20.22,0v6.58h6.17V45.63Zm-10,0v6.58h6.17V45.63ZM146.93,69.79v6.58h6.17V69.79Zm20.22,0v6.58h6.17V69.79Zm-10,0v6.58h6.17V69.79Zm-27.57.11v6.58h6.17V69.9Z' />
          <path fill='#F9F6EF' fillRule='evenodd' d='M356.49,93.78h-80V39.07h19.41v49.3h6V52.75h8.75V29.2L333.59,34V88.37h5.09V55.56h17.81Zm-14.3-35.4v3.5h2.54v-3.5ZM320.38,74.66v4.62h3.34V74.66Zm0-27.8v4.61h3.34V46.86Zm0,7v4.61h3.34V53.81Zm0,6.95v4.61h3.34V60.76Zm0,6.95v4.61h3.34V67.71Zm0-27.8v4.61h3.34V39.91Zm-6.2,34.75v4.62h3.34V74.66Zm0-27.8v4.61h3.34V46.86Zm0,7v4.61h3.34V53.81Zm0,6.95v4.61h3.34V60.76Zm0,6.95v4.61h3.34V67.71Zm0-27.8v4.61h3.34V39.91ZM279.82,79v4.61h3.34V79Zm0-27.81v4.61h3.34V51.2Zm0,6.95v4.61h3.34V58.15Zm0,6.95v4.62h3.34V65.1Zm0,7v4.62h3.34V72.05Zm0-27.8v4.61h3.34V44.25Zm71.23,30v3.51h2.53V74.2Zm0-5.27v3.5h2.53v-3.5Zm0-5.27v3.5h2.53v-3.5Zm0-5.28v3.5h2.53v-3.5ZM346.69,74.2v3.51h2.54V74.2Zm0-5.27v3.5h2.54v-3.5Zm0-5.27v3.5h2.54v-3.5Zm0-5.28v3.5h2.54v-3.5Zm-4.5,15.82v3.51h2.54V74.2Zm0-5.27v3.5h2.54v-3.5Zm0-5.27v3.5h2.54v-3.5Z' />
          <path fill='#F9F6EF' fillRule='evenodd' d='M62.77,54.43h43.71l3.91,9h-3.25V93.78H90V79.68c-3.54-2.11-7-2.24-10.27,0v14.1H61.85V63.39H58.48l4.29-9ZM95.35,79.68V86.8H94v1.31h9.15V86.8H101.7V79.68Zm0-12.88v7.12H94v1.31h9.15V73.92H101.7V66.8Zm-13.72,0v7.12H80.25v1.31H89.4V73.92H88V66.8ZM67.27,79.68V86.8H65.89v1.31H75V86.8H73.62V79.68Zm0-12.88v7.12H65.89v1.31H75V73.92H73.62V66.8Z' />
          <path fill='#F9F6EF' fillRule='evenodd' d='M46.58,66.1V84.48H47.8L47.91,73l11.3,2.33L58.47,79,56,78.49V89h.92v4.79H39.06V91.07H36.75V88.79H34.57v-11H27.73v11H25.39v2.28H23.07v2.71H1.29V89H2.55V78.75l-1.41.88L0,77.8l10-6.25.55-.34.56.33,4.07,2.36,4.15,2.59.29-.69L15.43,72V66.29l-2.08,1.3-2.08-3.34,18.24-11.4,1-.62,1,.59L50.19,63.65l-2,3.4-1.64-1ZM38.14,93.78V92H35.82V89.72h-9.5V92H24v1.78ZM19.3,65.14v7.18h5.22V65.14Zm19.16,0v7.18h5.21V65.14Zm0,12.75v7.19h5.21V77.89Zm-30.38,2V87h5.21V79.86Z' />
        </g>
      </g>
    </svg>

  </footer>

export default Footer
